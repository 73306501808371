import Link from "next/link";
import ProfileHeader from "@/components/common/ProfileHeader";
import { Box, Burger, Drawer, Image, Group, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Cookies from "js-cookie";
import { CommonHeaderMenuItem, ThemeVariant } from "@/types/navigation";
import { ProfileNavbarAccordionWithLoading } from "@/components/header/ProfileNavbarAccordion";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { getQueryClient } from "@/api";
import { contract } from "contract";
import ErrorMessage from "@/components/ErrorMessage";
import CustomErrorMessage from "@/components/CustomErrorMessage";
import closeButtonClass from "@/styles/notification.module.css";
import UnStyledButton from "@/components/button/UnstyledButton";
import usePosthogTracking from "@/hooks/usePosthogTracking";
import isNil from "lodash/isNil";
import { useLogout } from "@/hooks/useLogout";
import CustomAccordion from "@/components/header/CustomAccordion";
import UnStyledTextLink from "@/components/button/UnstyledTextLink";
import DropdownMenu from "@/components/header/landingPage/DropDownMenu";

const LandingPageHeader = () => {
  const router = useRouter();
  const path = router.asPath;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const { onLogout } = useLogout({
    onSuccessCallbackFunction: () => {
      setIsLoggedIn(false);
    },
  });
  const [opened, { toggle, close }] = useDisclosure();

  const signupMenuItems: CommonHeaderMenuItem[] = [
    {
      link: "/register",
      text: "as User",
    },
    {
      link: "/register/wedding-planner",
      text: "as Wedding Planner",
    },
    {
      link: "/register/vendor",
      text: "as Vendor",
    },
  ];

  const loginMenuItems: CommonHeaderMenuItem[] = [
    {
      link: "/login",
      text: "as User",
    },
    { link: "/login/wedding-planner", text: "as Wedding Planner" },
    { link: "/login/vendor", text: "as Vendor" },
  ];
  useEffect(() => {
    if (Cookies.get("connect.sid")) {
      setIsLoggedIn(true);
      return;
    }
    setIsLoggedIn(false);
  }, [path]);

  return (
    <Box pos="absolute" top={0} left={0} right={0}>
      <Drawer
        opened={opened}
        onClose={close}
        position="right"
        classNames={{ close: closeButtonClass.closeButton }}
        styles={{
          header: { backgroundColor: "#6AA39E", paddingInline: 30 },
          content: { backgroundColor: "#6AA39E" },
          body: { paddingInline: 30 },
          close: { color: "white" },
        }}
        c="white"
      >
        {isLoggedIn ? (
          <Stack gap={10}>
            <ProfileNavbarAccordionWithLoading onCloseDrawer={close} />
            <UnStyledButton
              label="Log out"
              onClick={() => {
                onLogout();
              }}
              fw={600}
            />
          </Stack>
        ) : (
          <>
            <CustomAccordion accordionControlLabel="Login">
              <Stack pl={0}>
                {loginMenuItems.map((item) => (
                  <Box onClick={() => item.onClick?.()}>
                    <UnStyledTextLink
                      key={item.text}
                      href={item.link}
                      label={item.text}
                      c="#f8f8f8"
                      fw={500}
                    />
                  </Box>
                ))}
              </Stack>
            </CustomAccordion>
            <CustomAccordion accordionControlLabel="Register">
              <Stack pl={0}>
                {signupMenuItems.map((item) => (
                  <Box onClick={() => item.onClick?.()}>
                    <UnStyledTextLink
                      key={item.text}
                      href={item.link}
                      label={item.text}
                      c="#f8f8f8"
                      fw={500}
                    />
                  </Box>
                ))}
              </Stack>
            </CustomAccordion>
          </>
        )}
      </Drawer>
      <Group
        pos="relative"
        justify="space-between"
        align="flex-end"
        pt={{ base: 16, xs: 38 }}
        px={{ base: 30, sm: 50 }}
        maw={1600}
        mx="auto"
      >
        <Box display={{ base: "none", md: "block" }} />
        <Link href="/" style={{ zIndex: 5 }}>
          <Image
            alt="uthsav-logo"
            w={{ base: 120, xs: 150 }}
            h={{ base: 50, xs: 60.5 }}
            src="/images/uthsav.webp"
          />
        </Link>
        <Box
          display={{ base: "none", sm: "block" }}
          pos="relative"
          style={{ zIndex: 5 }}
        >
          {isLoggedIn ? (
            <ProfileHeaderWithLoading
              onLogout={onLogout}
              isLoggedIn={isLoggedIn}
            />
          ) : (
            <Group>
              <DropdownMenu buttonText="Sign up" menuItems={signupMenuItems} />
              <DropdownMenu buttonText="Login" menuItems={loginMenuItems} />
            </Group>
          )}
        </Box>
        <Box display={{ base: "block", sm: "none" }}>
          <Burger
            size={20}
            opened={opened}
            onClick={toggle}
            color="white"
            style={{ position: "relative", zIndex: 6 }}
          />
        </Box>
      </Group>
    </Box>
  );
};

const ProfileHeaderWithLoading = ({
  onLogout,
  isLoggedIn,
}: {
  onLogout: () => void;
  isLoggedIn: boolean;
}) => {
  const { data, isLoading, error } = getQueryClient().user.getProfile.useQuery(
    [contract.user.getProfile.path],
    {}
  );
  usePosthogTracking({
    isLoggedIn,
    userDetails:
      isLoading || !isNil(error) || !data || data.status !== 200
        ? null
        : data.body,
  });
  if (isLoading) {
    return <></>;
  }
  if (error) {
    return <ErrorMessage error={error} h="100%" />;
  }
  if (data?.status !== 200) {
    return <CustomErrorMessage errorMessage="User data not available" />;
  }
  return (
    <ProfileHeader
      onLogout={onLogout}
      themeVariant={ThemeVariant.LIGHT}
      userDetails={data.body}
      isLoggedIn={isLoggedIn}
    />
  );
};
export default LandingPageHeader;
